import { setCharities, setDirty } from "../../redux/actions/charitiesActions";
import {
  setDirty as setThreadDirty,
  setThreads,
} from "../../redux/actions/threadActions";

import { downloadFile, sendRequest, sendRequestAsync } from "../apiService";
import { setProp } from "../../redux/actions/genericActions";
import store from "../../redux/store";

export function addLogo(charityId, image, callback) {
  var data = new FormData();
  data.append("file", image, image.name);

  sendRequest({
    request: {
      resource: `/administration/charities/logos/${charityId}`,
      method: "put",
    },
    data: data,
    config: { headers: { "Content-Type": "multipart/form-data" } },
    success: (response) => {
      callback(response);
    },
  });
}

export function addMyLogo(image, callback) {
  var data = new FormData();
  data.append("file", image, image.name);

  sendRequest({
    request: {
      resource: `/charities/me/logos`,
      method: "put",
    },
    data: data,
    config: { headers: { "Content-Type": "multipart/form-data" } },
    success: (response) => {
      callback(response);
    },
  });
}
export function getCharities(callback) {
  //if (store.getState().charities.isDirty) {
  sendRequest({
    request: {
      resource: "/administration/charities/",
      method: "get",
    },
    data: null,
    success: (response) => {
      store.dispatch(setCharities(response.data));
      store.dispatch(setDirty(false));
      callback(response);
    },
  });
  //}
}

export function getCharity(charityId, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charityId".replace(
        ":charityId",
        charityId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}
export function getMyCharityId(callback, failureCallback) {
  sendRequest({
    request: {
      resource: "/charities/me",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response.data.id);
    },
    failure: (response) => {
      failureCallback(response);
    },
  });
}

export async function getMyCharityAsync() {
  try {
    const response = await sendRequestAsync({
      request: {
        resource: "/charities/me",
        method: "get",
      },
      data: null,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getMyCharityChildrenAsync() {
  try {
    const response = await sendRequestAsync({
      request: {
        resource: "/charities/me/children",
        method: "get",
      },
      data: null,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function getMyCharity(callback) {
  sendRequest({
    request: {
      resource: "/charities/me",
      method: "get",
    },
    data: null,
    success: (response) => {
      store.dispatch(
        setProp("DASHBOARD", "mediaUploaded", response.data.totalMedia)
      );
      store.dispatch(
        setProp("DASHBOARD", "sharesSent", response.data.totalMediaShared)
      );
      store.dispatch(
        setProp("DASHBOARD", "sharesViewed", response.data.totalMediaViewed)
      );
      store.dispatch(
        setProp(
          "DASHBOARD",
          "twelveMonthShares",
          response.data.twelveMonthRollingMediaShared
        )
      );
      store.dispatch(
        setProp(
          "DASHBOARD",
          "twelveMonthViews",
          response.data.twelveMonthRollingMediaViewed
        )
      );
      store.dispatch(setProp("CHARITIES", "myCharityName", response.data.name));
      store.dispatch(setProp("CHARITIES", "isParent", response.data.isParent));
      store.dispatch(
        setProp("CHARITIES", "smsEnabled", response.data.smsEnabled)
      );
      if (callback) callback(response.data);
    },
  });
}

export function updateCharity(charityId, charity, callback) {
  sendRequest({
    request: {
      resource: `/administration/charities/${charityId}`,
      method: "put",
    },
    data: charity,
    success: (response) => {
      store.dispatch(setDirty(true));
      callback(response);
    },
  });
}

export function updateMyCharity(charity, callback) {
  sendRequest({
    request: {
      resource: "/charities/me",
      method: "put",
    },
    data: charity,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateMyCharityAutoResponseEnabled(isEnabled, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/messages/auto-responses",
      method: "put",
    },
    data: { Enabled: isEnabled },
    success: (response) => {
      callback(response);
    },
  });
}

export async function updateMyCharityAutoResponseEnabledAsync(isEnabled) {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: "/charities/me/messages/auto-responses",
          method: "put",
        },
        data: { Enabled: isEnabled },
      },
      { supressAlert: true }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function updateCharityAutoResponseMesssage(message, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/messages/auto-responses/responses",
      method: "put",
    },
    data: { Messsage: message },
    success: (response) => {
      callback(response);
    },
  });
}

export async function updateCharityAutoResponseMesssageAsync(message) {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: "/charities/me/messages/auto-responses/responses",
          method: "put",
        },
        data: { Messsage: message },
      },
      { supressAlert: true }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export function createCharity(data, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/",
      method: "post",
    },
    data: data,
    success: (response) => {
      store.dispatch(setDirty(true));
      const splitLocation = response.headers.location.split("/");
      const charityId = splitLocation[splitLocation.length - 1];
      callback(charityId);
    },
  });
}

export function getAllMetrics(startDate, endDate, callback) {
  sendRequest({
    request: {
      resource: `/administration/charities/metrics/${startDate}/${endDate}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getUsageDetails(id, startDate, endDate, callback) {
  sendRequest({
    request: {
      resource: `/administration/charities/${id}/metrics/${startDate}/${endDate}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getAllCharityCampaigns(startDate, endDate, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/campaigns/${startDate}/${endDate}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function validateCampaign(name, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/campaigns/exists?name=${name}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getAllCharityMediaRequests(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getAllCharityMediaRequestsDetail(mediaRequestId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/media/requests/:id".replace(
        ":id",
        mediaRequestId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCampaignDetails(mediaShareId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/campaigns/:mediaShareId".replace(
        ":mediaShareId",
        mediaShareId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCharityChildren(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/children`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getCharityChild(charityId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/children/${charityId}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function createChildCharity(data, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children",
      method: "post",
    },
    data: data,
    success: (response) => {
      store.dispatch(setDirty(true));
      const splitLocation = response.headers.location.split("/");
      const charityId = splitLocation[splitLocation.length - 1];
      callback(charityId);
    },
  });
}

//integration stuff -->
export function getAutomationStatus(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations",
      method: "get",
    },
    success: (response) => {
      if (
        response.data.some(
          (item) => item.identifier === "mindBody" && item.isEnabled === true
        )
      ) {
        store.dispatch(setProp("DASHBOARD", "hasAutomations", true));
      }
      if (callback) {
        callback(response);
      }
    },
  });
}

export function getIntegrations(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations",
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
  });
}

export async function getIntegrationsAsync() {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: "/charities/me/integrations",
          method: "get",
        },
        data: null,
      },
      { supressAlert: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function getIntegrationFeatures(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/features",
      method: "get",
    },
    success: (response) => {
      callback(response);
    },
  });
}

export function getMindBodyIntegrationDetails(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/mind-body-online",
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getMindBodyIntegrationHistory(parameters, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/integrations/mind-body-online/synchronize?startDate=${parameters.startDate}&endDate=${parameters.endDate}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function downloadMindBodyIntegrationHistory(
  taskId,
  reportName,
  callback,
  failureCallback
) {
  downloadFile({
    request: {
      resource: `/charities/me/integrations/mind-body-online/synchronize/${taskId}?resultType=${reportName}`,
      method: "get",
    },
    success: (response) => {
      if (callback) callback(response);
    },
    failure: (response) => {
      if (failureCallback) failureCallback(response.response);
    },
  });
}

export function initializeMindBodyOnline(
  initializationData,
  callback,
  failureCallback
) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/mind-body-online/initialize",
      method: "post",
    },
    data: initializationData,
    success: (response) => {
      if (callback) callback(response);
    },
    failure: (response) => {
      if (failureCallback) failureCallback(response.response);
    },
  });
}

export function synchronizeMindBodyOnline(
  targetDate,
  callback,
  failureCallback
) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/mind-body-online/synchronize",
      method: "post",
    },
    data: targetDate,
    success: (response) => {
      if (callback) callback(response);
    },
    failure: (response) => {
      if (failureCallback) failureCallback(response.response);
    },
  });
}

export function getNeonCRMIntegrationDetails(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/neon-one",
      method: "get",
    },
    success: (response) => {
      callback(response);
    },
  });
}

export function initializeNeonCRM(initializationData, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/neon-one",
      method: "post",
    },
    data: initializationData,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function getVirtuousIntegrationDetails(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/virtuous",
      method: "get",
    },
    success: (response) => {
      callback(response);
    },
  });
}

export function initializeVirtuous(initializationData, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/virtuous",
      method: "post",
    },
    data: initializationData,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function synchronizeVirtuous(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/integrations/virtuous/sync",
      method: "post",
    },
    data: null,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

// <--

export function updateChildCharity(charityId, charity, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/children/${charityId}`,
      method: "put",
    },
    data: charity,
    success: (response) => {
      store.dispatch(setDirty(true));
      callback(response);
    },
  });
}

export function addChildLogo(charityId, image, callback) {
  var data = new FormData();
  data.append("file", image, image.name);
  sendRequest({
    request: {
      resource: `/charities/me/children/logos/${charityId}`,
      method: "put",
    },
    data: data,
    config: { headers: { "Content-Type": "multipart/form-data" } },
    success: (response) => {
      callback(response);
    },
  });
}

export function getMessageThreads(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/donors/messages/",
      method: "get",
    },
    data: null,
    success: (response) => {
      store.dispatch(setThreads(response.data));
      if (callback) callback(response);
    },
  });
}

export async function getMessageThreadsAsync(since = null) {
  try {
    const resource = since
      ? `/charities/me/donors/messages/?since=${encodeURIComponent(since)}`
      : "/charities/me/donors/messages/";

    const response = await sendRequestAsync(
      {
        request: {
          resource,
          method: "get",
        },
        data: null,
      },
      { supressAlert: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUnreadMessageThreadsAsync() {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: "/charities/me/donors/messages/unread",
          method: "get",
        },
        data: null,
      },
      { supressAlert: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function getMessageThread(donorId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/${donorId}/messages`,
      method: "get",
    },
    data: null,
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function sendMessage(donorId, message, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/${donorId}/messages`,
      method: "post",
    },
    data: { message },
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function readMessage(messageId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/messages/${messageId}`,
      method: "post",
    },
    data: null,
    success: (response) => {
      store.dispatch(setThreadDirty());
      getMessageThreads();
      if (callback) callback(response);
    },
  });
}

export function archiveThread(donorId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/${donorId}/messages/archive`,
      method: "put",
    },
    data: null,
    success: (response) => {
      store.dispatch(setThreadDirty());
      getMessageThreads();
      if (callback) callback(response);
    },
  });
}

export async function archiveThreadAsync(donorId) {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: `/charities/me/donors/${donorId}/messages/archive`,
          method: "put",
        },
        data: null,
      },
      { supressAlert: true }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
