import { sendRequest, sendRequestAsync } from "../apiService";

//Media specific templates
export function getMyMediaTemplates(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/media`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function createMediaShareTemplate(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/media`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        const splitLocation = response.headers.location.split("/");
        const shareSenderId = splitLocation[splitLocation.length - 1];
        callback(shareSenderId);
      }
    },
  });
}

export function updateMediaShareTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/media/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
  });
}

//Standard Share Templates
export function getMyStandardTemplates(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/standard`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export async function getMyStandardTemplatesAsync() {
  try {
    const response = await sendRequestAsync(
      {
        request: {
          resource: `/charities/me/share-templates/standard`,
          method: "get",
        },
        data: null,
      },
      { supressAlert: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function createStandardShareTemplate(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/standard`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function updateStandardShareTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/standard/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
  });
}

//legacy template endpoints still in use

export function getShareTemplates(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getShareTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/${id}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function createShareTemplate(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        const splitLocation = response.headers.location.split("/");
        const shareSenderId = splitLocation[splitLocation.length - 1];
        callback(shareSenderId);
      }
    },
  });
}

export function updateShareTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
  });
}

export function deleteShareTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-templates/${id}`,
      method: "delete",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getRequestTemplates(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests/templates`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getRequestTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests/templates/${id}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function createRequestTemplate(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests/templates`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        const splitLocation = response.headers.location.split("/");
        const shareSenderId = splitLocation[splitLocation.length - 1];
        callback(shareSenderId);
      }
    },
  });
}

export function updateRequestTemplate(id, data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests/templates/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
  });
}

export function deleteRequestTemplate(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/requests/templates/${id}`,
      method: "delete",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}
