// export const LANDING = "/";
export const DASHBOARD = "/dashboard";

// Locker routes
export const MY_LOCKER = "/locker";

// Donor Routes
const _DONOR_PREFIX = "/supporters";
export const DONOR_ROUTES = {
  CREATE: _DONOR_PREFIX + "/create",
  UPDATE: _DONOR_PREFIX + "/update/:donorId",
  DONORS: _DONOR_PREFIX + "/supporters",
  DONOR_DETAIL: _DONOR_PREFIX + "/supporters/:donorId",
  DONATIONS: _DONOR_PREFIX + "/contributions",
  IMPORT_DONATIONS: _DONOR_PREFIX + "/import",
  THREADS: _DONOR_PREFIX + "/thread",
  THREAD_DETAIL: _DONOR_PREFIX + "/thread/:donorId",
  // Public donor request routes
  PUBLIC_DONOR_REQUESTS: _DONOR_PREFIX + "/requests",
  CREATE_PUBLIC_DONOR_REQUEST: _DONOR_PREFIX + "/requests/create",
  EDIT_PUBLIC_DONOR_REQUEST: _DONOR_PREFIX + "/requests/edit/:id",

  // Public donor request routes
  PUBLIC_DONOR_REQUEST: _DONOR_PREFIX + "/request/public/:id",

  // Bulk tagging routes
  BULK_TAGGING_MBO: _DONOR_PREFIX + "/bulk-tagging/mind-body-online",
};

// Auth Routes
const _AUTH_PREFIX = "/auth";
export const AUTH_ROUTES = {
  // SIGN_IN: _AUTH_PREFIX + "/signin",
  SIGN_IN: "/",
  SIGN_UP_FORWARD: _AUTH_PREFIX + "/signup/:userId",
  SIGN_UP: _AUTH_PREFIX + "/signup/",
  ERROR_404: _AUTH_PREFIX + "/404",
  ERROR_500: _AUTH_PREFIX + "/500",
};

// Admin Routes
const _ADMIN_PREFIX = "/admin";
export const ADMIN_ROUTES = {
  CHARITIES: _ADMIN_PREFIX + "/organizations",
  EDIT_CHARITY: _ADMIN_PREFIX + "/organization/edit/:id",
  CREATE_CHARITY: _ADMIN_PREFIX + "/organization/create/:parentid",
  CHARITY_ORGANIZATION_USAGE: _ADMIN_PREFIX + "/organization/metrics",
  CHARITY_USAGE_DETAILS: _ADMIN_PREFIX + "/organization/:id/metrics",
  SYSTEM_ADMINS: _ADMIN_PREFIX + "/systemadmins",
  EDIT_SYSTEM_ADMIN: _ADMIN_PREFIX + "/systemadmin/edit/:id",
  CREATE_SYSTEM_ADMIN: _ADMIN_PREFIX + "/systemadmin/create",
  INVITE_CHARITY_USER: _ADMIN_PREFIX + "/organization/:id/inviteuser",
  EDIT_CHARITY_USER:
    _ADMIN_PREFIX + "/organization/:charityId/user/:userId/edit",

  //request templates
  REQUEST_TEMPLATES: _ADMIN_PREFIX + "/request-templates",
  REQUEST_TEMPLATES_CREATE: _ADMIN_PREFIX + "/request-templates/create",
  REQUEST_TEMPLATE_EDIT: _ADMIN_PREFIX + "/request-templates/edit/:id",

  //share templates
  SHARE_TEMPLATES: _ADMIN_PREFIX + "/share-templates",
  SHARE_TEMPLATES_CREATE: _ADMIN_PREFIX + "/share-templates/create",
  SHARE_TEMPLATE_EDIT: _ADMIN_PREFIX + "/share-templates/edit/:id",

  // group administrators
  GROUP_ADMINS: _ADMIN_PREFIX + "/group-administrators",
  CREATE_GROUP_ADMIN: _ADMIN_PREFIX + "/group-administrators/create",
  EDIT_GROUP_ADMIN: _ADMIN_PREFIX + "/group-administrators/edit/:id",

  // organization administator
  ORGANIZATION_ADMINS: _ADMIN_PREFIX + "/organization-administrators",
  CREATE_ORGANIZATION_ADMIN:
    _ADMIN_PREFIX + "/organization-administrators/create",
  EDIT_ORGANIZATION_ADMIN:
    _ADMIN_PREFIX + "/organization-administrators/edit/:id",
};

// group administator routes
const _GROUP_ADMIN_PREFIX = "/group-administrators";
export const GROUP_ADMIN_ROUTES = {
  SENT_REPORT: _GROUP_ADMIN_PREFIX + "/sent-report",
  SENT_REPORT_DETAILS: _GROUP_ADMIN_PREFIX + "/sent-report/:id",
};

// Account Routes
const _ACCOUNT_PREFIX = "/account";
export const ACCOUNT_ROUTES = {
  MY_CHARITY: "/myorganization/me",

  USERS: _ACCOUNT_PREFIX + "/users",
  PROFILE: _ACCOUNT_PREFIX + "/profile",
  SUBSCRIPTION: _ACCOUNT_PREFIX + "/subscription",
  INVITE_USER: _ACCOUNT_PREFIX + "/sendinvite",
  EDIT_USER: _ACCOUNT_PREFIX + "/editUser/:userId",

  CTA_BUTTONS: "/myorganization/calltoactionbuttons",

  SHARE_SENDERS: _ACCOUNT_PREFIX + "/sharesenders",
  CREATE_SHARE_SENDER: _ACCOUNT_PREFIX + "/newShareSender",
  EDIT_SHARE_SENDER: _ACCOUNT_PREFIX + "/editShareSender/:shareSenderId",

  SHARE_TEMPLATES: "/campaigns/shareTemplates",
  CREATE_SHARE_TEMPLATE: "/campaigns/newShareTemplate",
  EDIT_SHARE_TEMPLATE: "/campaigns/editShareTemplate/:shareTemplateId",

  CREATE_STANDARD_TEMPLATE: "/campaigns/newStandardTemplate",
  EDIT_STANDARD_TEMPLATE: "/campaigns/editStandardTemplate/:standardTemplateId",

  REQUEST_TEMPLATES: "/campaigns/requestTemplates",
  CREATE_REQUEST_TEMPLATE: "/campaigns/newRequestTemplate",
  EDIT_REQUEST_TEMPLATE: "/campaigns/editRequestTemplate/:requestTemplateId",

  API_KEYS: _ACCOUNT_PREFIX + "/apikeys",
  CREATE_API_KEY: _ACCOUNT_PREFIX + "/newapikey",
  EDIT_API_KEY: _ACCOUNT_PREFIX + "/editapikey/:apiKeyId",

  // Organization routes
  ORGANIZATIONS: _ACCOUNT_PREFIX + "/organizations",
  CREATE_ORGANIZATION: _ACCOUNT_PREFIX + "/organizations/create",
  EDIT_ORGANIZATION: _ACCOUNT_PREFIX + "/organizations/edit/:id",
  CREATE_ORGANIZATION_USER:
    _ACCOUNT_PREFIX + "/organizations/:charityId/users/create",
  EDIT_ORGANIZATION_USER:
    _ACCOUNT_PREFIX + "/organizations/:charityId/users/edit/:userId",

  // Integrations
  INTEGRATION_ALL: _ACCOUNT_PREFIX + "/integration",
  INTEGRATION_NEON_ONE_CRM: _ACCOUNT_PREFIX + "/integration/neon-one",
  INTEGRATION_VIRTUOUS: _ACCOUNT_PREFIX + "/integration/virtuous",
  INTEGRATION_MIND_BODY: _ACCOUNT_PREFIX + "/integration/mind-body",

  // Public media request routes
  PUBLIC_MEDIA_REQUESTS: "/locker/publicMediaRequests",
  CREATE_PUBLIC_MEDIA_REQUEST: "/locker/publicMediaRequests/create",
  EDIT_PUBLIC_MEDIA_REQUEST: "/locker/publicMediaRequests/edit/:id",
};

// Media Routes
const _MEDIA_PREFIX = "/media";
export const MEDIA_ROUTES = {
  EDIT: _MEDIA_PREFIX + "/edit/:mediumId",
  CREATE: _MEDIA_PREFIX + "/create",
  UPLOAD_LOGO: _MEDIA_PREFIX + "/logo/upload",

  THUMBNAILS: _MEDIA_PREFIX + "/thumbnails",
  EDIT_THUMBNAIL: _MEDIA_PREFIX + "/thumbnails/edit/:mediumId",

  REQUEST: "/locker/request",

  REQUEST_PREVIEW: "/locker/requestPreview/:message",
  REQUEST_DETAILS: "/locker/request/:requestId/:donorId",
  REQUEST_UPLOAD_COMPLETE: "/locker/request/uploadcomplete",

  // Public media request routes
  PUBLIC_MEDIA_REQUEST: _MEDIA_PREFIX + "/request/public/:id",
};

// Share Routes
const _SHARE_PREFIX = "/share";
export const SHARE_ROUTES = {
  DETAILS: _SHARE_PREFIX + "/:shareId",
  CREATE: _SHARE_PREFIX + "/create/:mediumId",
  CREATEBLANK: _SHARE_PREFIX + "/create/",
  PREVIEW: _SHARE_PREFIX + "preview/:mediumId",
  RESPONSES: "/myorganization/responses",
  DRAFTS: "/campaigns/drafts",
  SCHEDULED: "/campaigns/scheduled/",
  SCHEDULED_DETAILS: "/campaigns/scheduled/:id",
  QUICK_TEXT: _SHARE_PREFIX + "/quick-text",
};

// Automation Routes
const _AUTOMATION_PREFIX = "/automations";
export const AUTOMATION_ROUTES = {
  INDEX: _AUTOMATION_PREFIX,
  EDIT: _AUTOMATION_PREFIX + "/edit",
  UPDATE: _AUTOMATION_PREFIX + "/edit/:automationId",
};

// Journey Routes
const _JOURNEY_PREFIX = "/journeys";
export const JOURNEY_ROUTES = {
  INDEX: _JOURNEY_PREFIX,
  EDIT: _JOURNEY_PREFIX + "/edit/:journeyId",
  ENROLL: _JOURNEY_PREFIX + "/enroll/:journeyId",
};

// Info
const _INFO_PREFIX = "/info";
export const INFO_ROUTES = {
  ABOUT: _INFO_PREFIX + "/about",
  PRIVACY_POLICY: _INFO_PREFIX + "/privacypolicy",
  UNSUBSCRIBED: _INFO_PREFIX + "/unsubscribe/:firstName/:lastName/:shareId",
};

// Reports
const _REPORTS_PREFIX = "/reports";
export const REPORTS_ROUTES = {
  UNSUBSCRIBED: _REPORTS_PREFIX + "donors/unsubscribed",
  SHARE_METRICS: _REPORTS_PREFIX + "/campaigns/metrics",
  MEDIA_REQUEST_REPORTS: _REPORTS_PREFIX + "/campaigns/request-reports",
  MEDIA_REQUEST_REPORTS_DETAILS:
    _REPORTS_PREFIX + "/campaigns/request-reports/:id",
  SHARE_METRICS_DETAILS: _REPORTS_PREFIX + "/campaigns/metrics/:mediaShareId",
  SMS_STOP: _REPORTS_PREFIX + "/donors/sms-stop",
};
