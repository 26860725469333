import React from "react";
import { Container, Spinner } from "reactstrap";

const Loader = ({ text }) => (
  <Container
    fluid
    className="vh-50 d-flex justify-content-center align-items-center"
  >
    <div className="text-center">
      <Spinner color="primary" />
      {text && <div className="mt-2">{text}</div>}
    </div>
  </Container>
);

export default Loader;
