import { PlusCircle } from "react-feather";
import React from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
const userTz = dayjs.tz.guess();

export function shortDateFormatter(cell) {
  return cell ? new Date(cell).toLocaleDateString() : "";
}

export function shortDateTimeFormatter(cell) {
  return dayjs.utc(cell).tz(userTz).format("M/D/YYYY h:mm A");
}

export function moneyFormatter(cell) {
  return new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
  }).format(cell / 100);
}

export function listFormatter(cell) {
  return cell ? cell.join(", ") : "";
}

export function selectableFormatter(cell) {
  return (
    <span>
      <PlusCircle size={14} style={{ marginTop: -3 }} />
      {"   "} {cell}
    </span>
  );
}

export function phoneFormatter(phoneNumber) {
  return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}
